@custom-media --mobile (width < 480px);
@custom-media --mobile-large (480px <= width < 768px);
@custom-media --until-tablet (width < 768px);
@custom-media --tablet (768px <= width < 1024px);
@custom-media --until-desktop (width < 1024px);
@custom-media --desktop (1024px <= width < 1400px);
@custom-media --until-desktop-wide (width < 1400px);
@custom-media --desktop-wide (width >= 1400px);

@font-face {
    font-family: 'Cooper Hewitt';
    src:
        url(../fonts/cooperhewitt-book-webfont.woff2) format('woff2'),
        url(../fonts/cooperhewitt-book-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src:
        url(../fonts/cooperhewitt-semibold-webfont.woff2) format('woff2'),
        url(../fonts/cooperhewitt-semibold-webfont.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

:root {
    --orange: #f6a623;
    --grey: #424242;
    --blue: #6b8b9e;
    --blue-dark: #2e3946;
    --clay: #625f4c;
    --clay-dark: #605c3d;
    --green: #3c482d;
    --green-dark: #393720;
    --brown: #363129;
    --brown-dark: #3d3a2e;
    --brown-darker: #3a2b1d;
    --brown-darkest: #3b2c1d;
    --brown-really-dark: #26100b;
    --grey-brown: #3c392d;

    --link-color: #33f;
    --link-secondary: #0563c1;
    --link-color-hover: #306;
    --text-color: #333;
    --text-dark: #323335;

    --accessibility: #ff10fc;

    --laboratory: #5c797f;
    --farm: #72553c;
    --site: #4a4a4a;
    --workshop: #27100c;
    --factory: #4e4520;
    --ward: #c9c6d0;
    --bakery: #5d5948;
    --leisure: #bccbd1;
    --salon: #dcdcdc;
    --emergency: #666;
    --garage: #27100c;
    --mine: #000;

    --font-sans: 'Cooper Hewitt', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    --font-serif: georgia, 'Times New Roman', times, serif;
    --font-mono: monaco, menlo, consolas, 'Courier New', monospace;

    --font-size: 16px;
    --font-size-h1: 60px;
    --font-size-h2: 44px;
    --font-size-h3: 25px;
    --font-size-h4: 18px;

    --line-height-base: calc(25 / 16 * 100%);
}
