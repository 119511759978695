.progress__pre-title {
    text-transform: uppercase;
    margin-bottom: 10px;
}

.progress__title {
    font-size: 32px;
    font-weight: bold;
    line-height: calc(42 / 32 * 100%);
    text-transform: none;
    max-width: calc(650 / 960 * 100%);
    margin-top: 0;
    margin-bottom: 40px;

    &.is_hidden {
        display: none;
    }

    &.is_visible {
        display: block;
    }

    @media (--tablet) {
        font-size: 28px;
        margin-bottom: 30px;
    }

    @media (--until-desktop) {
        max-width: 100%;
        font-size: 22px;
        margin-bottom: 20px;
    }
}

.progress__meter {
    width: 100%;
    height: 5px;
    position: relative;
    margin-bottom: 40px;

    @media (--tablet) {
        margin-bottom: 30px;
    }

    @media (--until-tablet) {
        margin-bottom: 20px;
    }
}

.progress__marker {
    float: left;
    height: 100%;
    background-color: rgba(255,255,255,.2);
    top: 0;
    left: 0;
    width: 16%;
    border-radius: 2px;
    margin-right: .666%;
    transition: 1s background-color ease;

    &:last-child {
        margin-right: 0;
    }
}

.progress__meter--1 .progress__marker--1 {
    background-color: #fff;
}

.progress__meter--2 .progress__marker--1,
.progress__meter--2 .progress__marker--2 {
    background-color: #fff;
}

.progress__meter--3 .progress__marker--1,
.progress__meter--3 .progress__marker--2,
.progress__meter--3 .progress__marker--3 {
    background-color: #fff;
}

.progress__meter--4 .progress__marker--1,
.progress__meter--4 .progress__marker--2,
.progress__meter--4 .progress__marker--3,
.progress__meter--4 .progress__marker--4 {
    background-color: #fff;
}

.progress__meter--5 .progress__marker--1,
.progress__meter--5 .progress__marker--2,
.progress__meter--5 .progress__marker--3,
.progress__meter--5 .progress__marker--4,
.progress__meter--5 .progress__marker--5 {
    background-color: #fff;
}

.progress__meter--6 .progress__marker--1,
.progress__meter--6 .progress__marker--2,
.progress__meter--6 .progress__marker--3,
.progress__meter--6 .progress__marker--4,
.progress__meter--6 .progress__marker--5,
.progress__meter--6 .progress__marker--6 {
    background-color: #fff;
}
