.quiz__step--questions-0 {
    background-color: var(--clay);
}

.quiz__step--questions-1 {
    background-color: var(--clay-dark);
}

.quiz__step--questions-2 {
    background-color: var(--green);
}

.quiz__step--questions-3 {
    background-color: var(--blue-dark);
}

.quiz__step--questions-4 {
    background-color: var(--brown);
}

.quiz__step--questions-5 {
    background-color: var(--green-dark);
}

.quiz__step--survey {
    background-color: var(--grey-brown);
}

.quiz__step--results {
    background-color: var(--brown-darkest);
}

/* .quiz__step-- {
    background-color: var(--brown-really-dark);
} */

.laboratory {
    background-color: var(--laboratory);
}

.farm {
    background-color: var(--farm);
}

.site {
    background-color: var(--site);
}

.workshop {
    background-color: var(--workshop);
}

.factory {
    background-color: var(--factory);
}

.ward {
    background-color: var(--ward);
}

.bakery {
    background-color: var(--bakery);
}

.leisure {
    background-color: var(--leisure);
}

.salon {
    background-color: var(--salon);
}

.emergency {
    background-color: var(--emergency);
}

.garage {
    background-color: var(--garage);
}

.mine {
    background-color: var(--mine);
}

.icon-animal-contact {
    background-image: url(../img/icons/icon-animal-contact.png);
}

.icon-bacteria {
    background-image: url(../img/icons/icon-bacteria.png);
}

.icon-clean {
    background-image: url(../img/icons/icon-clean.png);
}

.icon-cleaning-products {
    background-image: url(../img/icons/icon-cleaning-products.png);
}

.icon-dust {
    background-image: url(../img/icons/icon-dust.png);
}

.icon-flour {
    background-image: url(../img/icons/icon-flour.png);
}

.icon-fumes {
    background-image: url(../img/icons/icon-fumes.png);
}

.icon-health-check {
    background-image: url(../img/icons/icon-health-check.png);
}

.icon-label {
    background-image: url(../img/icons/icon-label.png);
}

.icon-mask {
    background-image: url(../img/icons/icon-mask.png);
}

.icon-risk-assessment {
    background-image: url(../img/icons/icon-risk-assessment.png);
}

.icon-smoke {
    background-image: url(../img/icons/icon-smoke.png);
}

.icon-ventilation {
    background-image: url(../img/icons/icon-ventilation.png);
}

.icon-warning {
    background-image: url(../img/icons/icon-warning.png);
}

.icon-work-carefully {
    background-image: url(../img/icons/icon-work-carefully.png);
}

.quiz-intro,
.bg-factsheet {
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 100% auto;

    @media (--until-tablet) {
        background-size: 100% auto;
        background-position: -50px 0;
        padding-top: 200px;
    }
}

.bg-landing {
    background-image: url(../img/backgrounds/bg-landing-top.png);
}

.quiz-intro {
    background-image: url(../img/backgrounds/bg-home-top.png);
}

.bg-workplace-risks-top {
    background-image: url(../img/backgrounds/bg-workplace-risks-top.png);
}

.bg-bakery-top {
     background-image: url(../img/backgrounds/bg-bakery-top.png);
 }

.bg-emergency-top {
    background-image: url(../img/backgrounds/bg-emergency-top.png);
}

.bg-factory-top {
    background-image: url(../img/backgrounds/bg-factory-top.png);
}

.bg-farm-top {
    background-image: url(../img/backgrounds/bg-farm-top.png);
}

.bg-garage-top {
    background-image: url(../img/backgrounds/bg-garage-top.png);
}

.bg-laboratory-top {
    background-image: url(../img/backgrounds/bg-laboratory-top.png);
}

.bg-landing-top {
     background-image: url(../img/backgrounds/bg-landing-top.png);
 }

.bg-leisure-top {
    background-image: url(../img/backgrounds/bg-leisure-top.png);
}

.bg-mine-top {
    background-image: url(../img/backgrounds/bg-mine-top.png);
}

.bg-salon-top {
     background-image: url(../img/backgrounds/bg-salon-top.png);
 }

.bg-site-top {
    background-image: url(../img/backgrounds/bg-site-top.png);
}

.bg-ward-top {
    background-image: url(../img/backgrounds/bg-ward-top.png);
}

.bg-workshop-top {
    background-image: url(../img/backgrounds/bg-workshop-top.png);
}
