.site-header {
    height: 150px;
    background-color: rgba(255,255,255,.35);
    padding-top: 0;

    @media (--tablet) {
        height: 100px;
        padding-top: 0;
    }
}

.header-additional {
    float: right;
}

.site-logo,
.elf-logo {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    text-indent: 115%;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    float: left;
    transition: .5s opacity ease;

    &:hover {
        opacity: .6;
    }

    &:focus {
        opacity: .3;
        outline: 0;
    }
}

.site-logo {
    height: 150px;
    width: 349px;
    background-image: url(../img/rivm_logo.png);
    float: left;
}

.elf-logo {
    width: 87px;
    height: 18px;
    margin-top: 6px;
    background-image: url(../img/elf-logo.png);
    float: left;
}

@media (--until-tablet) {
    .site-header {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .site-logo {
        width: 130px;
        height: 10px;
    }

    .elf-logo {
        height: 10px;
        width: 49px;
    }

    .site-logo,
    .elf-logo {
        margin-top: 2px;
    }
}
